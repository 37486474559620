<template>
  <div>
    <div style="display:flex; gap:10px;margin-top:10px;">
      <button
        class="button3"
        @click="addCustomArticle"
      >
        {{ $t('AddCustomArticle') }}
      </button>
      <div class="search-toggle">
        <p>{{ $t('SearchBy') }}</p>
        <div>
          <p
            :class="{ active2: toggleSearch === 'ArticleNumber' }"
            @click="toggleName('ArticleNumber')"
          >
            {{ $t('ArticleNumber') }}
          </p>
          <!-- <p
            :class="{ active2: toggleSearch === 'Code' }"
            @click="toggleSearch = 'Code';toggleName('Code')"
          >
            {{ $t('Code') }}
          </p> -->
          <p
            :class="{ active2: toggleSearch === 'Barcode' }"
            @click="toggleName('Barcode')"
          >
            {{ $t('Barcode') }}
          </p>
        </div>
      </div>
      <div
        class="searchfilter"
        style="display: flex;justify-content: space-between;"
      >
        <div
          v-if="toggleSearch === 'ArticleNumber'"
          style="display: flex; align-items: center"
        >
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchArticle == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="searchArticle != ''"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="searchArticle = ''"
              />
              <input
                v-model="searchArticle"
                type="text"
                autocomplete="off"
                name="search-box"
                :placeholder="$t('ArticleNumber')"
                :style="getArticleNameLike.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                @keyup="inputChanged"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
              >
            </div>
            <div
              v-if="getArticleNameLike.length > 0"
              ref="scrollContainer"
              class="dropdrop"
              :style="getArticleNameLike.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getArticleNameLike"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounter }"
                @click="searchByOn(result);"
              >
                <p
                  style="margin: 0">
                  {{ result.articleNumber }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="toggleSearch === 'Code'"
          style="display: flex; align-items: center"
        >
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchCode == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="searchCode != ''"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="searchCode = ''"
              />
              <input
                v-model="searchCode"
                type="text"
                autocomplete="off"
                name="search-box"
                :placeholder="$t('Code')"
                :style="getFilteredDresses.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                @keyup="inputChanged1"
                @keydown.down="onArrowDown1"
                @keydown.up="onArrowUp1"
              >
            </div>
            <div
              v-if="getFilteredDresses.length > 0"
              ref="scrollContainer"
              class="dropdrop"
              :style="getFilteredDresses.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getFilteredDresses"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounter1 }"
                @click="searchByCode(result);"
              >
                <p style="margin: 0">
                  {{ result.name }} - {{ result.code }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="toggleSearch === 'Barcode'"
          style="display: flex; align-items: center"
        >
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchBarcode == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="searchBarcode != ''"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="searchBarcode = ''"
              />
              <input
                v-model="searchBarcode"
                type="text"
                autocomplete="off"
                name="search-box"
                :placeholder="$t('Barcode')"
                :style="getArticleByBarcode.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                @keyup="inputChangedBarcode"
                @keydown.down="onArrowDownBarcode"
                @keydown.up="onArrowUpBarcode"
              >
            </div>
            <div
              v-if="getArticleByBarcode.length > 0"
              ref="scrollContainer"
              class="dropdrop"
              :style="getArticleByBarcode.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getArticleByBarcode"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounter }"
                @click="searchByBarcode(result);"
              >
                <p style="margin: 0">
                  {{ result.articleNumber }} - {{ result.barcode }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p style="color:red;">
        {{ warningText }}
      </p>
    </div>
    <div
      v-if="articleList.length > 0"
      style="border: 1px solid lightgrey; border-radius: 12px;padding: 10px;margin-top: 10px;"
    >
      <section
        v-for="(articles, index) in articleList"
        :key="index"
        class="custom-modal__wrapper"
        style="margin-bottom: 20px; flex-direction: column"
      >
        <div
          class="paragraphText"
          style="justify-content: space-between;"
        >
          <h3 v-if="articles.typeOfArticle !== 'Custom article'">
            {{ articles.articleNumber }}
          </h3>
          <b-form-group
            v-else
            :label="$t('CustomArticleNumber')"
          >
            <b-form-input
              v-model="articles.orderedArticleNumber"
              type="text"
              :placeholder="$t('ArticleNumber')"
              style="width: 100%"
            />
          </b-form-group>
          <div style="display:flex; gap:10px;">
            <b-icon-x-circle
              style="cursor:pointer;"
              @click="removeArticle(index)"
            />
            <b-icon-chevron-down
              v-if="!articles.isCollapsed"
              style="cursor:pointer; margin-left: 10px;"
              @click="toggleCollapse(index)"
            />
            <b-icon-chevron-up
              v-if="articles.isCollapsed"
              style="cursor:pointer; margin-left: 10px;"
              @click="toggleCollapse(index)"
            />
          </div>
        </div>
        <b-collapse
          :id="'collapse-' + index"
          :visible="articles.isCollapsed"
          class="mt-2"
        >
          <div
            class="tt"
            style="padding-top: 16px"
          >
            <table
              class="team_table"
            >
              <thead>
                <tr>
                  <th>{{ $t('AmountS') }}</th>
                  <th>{{ $t('PriceNoVat') }}</th>
                  <th>{{ $t('Vat') }}</th>
                  <th>{{ $t('PriceWithVat') }}</th>
                  <th>{{ $t('Discount') }}</th>
                  <th>{{ $t('DiscountedPrice') }}</th>
                  <th>{{ $t('AmountWithoutVat') }}</th>
                  <th>{{ $t('AmountWithVat') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b-form-input
                      v-model.number="articles.quantity"
                      type="number"
                      :placeholder="$t('Amount')"
                      @input="calculateFromAmount(articles)"
                    />
                    <p
                      v-if="articles.validationamount == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td>
                    <b-form-input
                      v-model.number="articles.priceWithoutVAT"
                      type="number"
                      :placeholder="$t('Value')"
                      :disabled="articles.quantity == 0 || articles.quantity == '' || articles.quantity == null"
                      @input="calculateFromPriceWithoutVAT(articles)"
                    />
                    <p
                      v-if="articles.validationPriceWithoutVAT == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td>
                    <b-form-radio-group
                      v-model="articles.selectedVAT"
                      :options="vatOptions"
                      style="display:flex;gap: 5px;align-items: center;"
                      @input="calculateFromSelectedVAT(articles)"
                    />
                  </td>
                  <td>
                    <b-form-input
                      v-model.number="articles.priceWithVAT"
                      type="number"
                      :placeholder="$t('Value')"
                      :disabled="articles.quantity == 0 || articles.quantity == '' || articles.quantity == null"
                      @input="calculateFromPriceWithVAT(articles)"
                    />
                    <p
                      v-if="articles.validationPriceWithVAT == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td>
                    <div style="display:flex;margin-right: 5px;">
                      <b-input
                        v-model.number="articles.discount"
                        type="number"
                        default="0"
                        :disabled="articles.priceWithVAT == 0 || articles.priceWithVAT == ''"
                      />
                      <b-form-select
                        v-model="articles.discountType"
                        style="height:38px; border-radius: 0.25rem; border: 1px solid #EAECF0"
                      >
                        <option value="$">
                          $
                        </option>
                        <option value="€">
                          €
                        </option>
                        <option value="CHF">
                          CHF
                        </option>
                        <option value="%">
                          %
                        </option>
                      </b-form-select>
                    </div>
                  </td>
                  <td>
                    <div style="display:flex">
                      <b-input
                        v-model.number="articles.discountedPrice"
                        disabled
                        type="number"
                        style="width: 90%;"
                      />
                      <b-form-select
                        v-model="articles.currency"
                        dropup
                        required
                        :options="currencies"
                        aria-placeholder="Select order type"
                        aria-describedby="input-1-live-feedback"
                        style="display: block;
                          padding: 0.469rem 0.75rem;
                          font-size: 1rem;
                          font-weight: 400;
                          line-height: 1.5;
                          color: #212529;
                          background-color: #fff;
                          background-clip: padding-box;
                          border: 1px solid #ced4da;
                          border-radius: 0.25rem;
                          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                      />
                    </div>
                  </td>
                  <td>
                    <b-form-input
                      v-model.number="articles.valueWithoutVAT"
                      type="number"
                      :placeholder="$t('Value')"
                      disabled
                    />
                    <p
                      v-if="articles.validationValueWithVAT == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                  <td>
                    <b-form-input
                      v-model.number="articles.valueWithVAT"
                      type="number"
                      :placeholder="$t('Value')"
                      disabled
                    />
                    <p
                      v-if="articles.validationValueWithVAT == true"
                      style="color:lightcoral"
                    >
                      {{ $t('TheValue') }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="formInput"
            style="align-items: baseline;"
          >
            <b-form-group
              :label="$t('PickupDate')"
            >
              <date-picker
                v-model="articles.pickedUpDate"
                format="YYYY-MM-DD"
                value-type="format"
                :clearable="false"
                :placeholder="$t('PickUpDate')"
              />
            </b-form-group>
          </div>
        </b-collapse>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment'

export default {
  components: {
    DatePicker,
  },
  props: ['articleList'],
  data() {
    return {
      currencies: ['€', '$', '%'],
      toggleSearch: 'ArticleNumber',
      searchArticle: '',
      searchCode: '',
      searchBarcode: '',
      arrowCounter: 0,
      arrowCounter1: 0,
      enterPressed: false,
      skipWatch: false,
      warningText: '',
      pageNumberFilter: 1,
      pageSizeFilter: 5,
      vatOptions: [
        { text: '18%', value: 18 },
        { text: '8%', value: 8 },
        { text: '0%', value: 0 },
      ],
    };
  },
  computed: {
    ...mapGetters(['getArticleNameLike',
      'getMaterials',
      'getIsLoading',
      'getCurrentPageForMaterials',
      'getTotalItemsForMaterials',
      'getMatVarCurrentPage',
      'getTotalPriceByMaterialId',
      'getFilteredDresses',
      'getArticleByBarcode',
      'getSupplierDropDown',
      'getAccountDetails',
      'getTotalItemsForMaterials',
      'getCurrentPageForMaterials',
      'getTotalPagesForAll',
      'getLocationsMoving',
      'getSellPriceByBarcode',
      'getPriceForArticle',
    ]),
  },
  watch: {
    articleList: {
      handler(newValue) {
        newValue.forEach((article, index) => {
          this.resetQuantityIfNegative(article);

          if (this.timeoutIds && this.timeoutIds[index]) {
            clearTimeout(this.timeoutIds[index]);
          }
          this.timeoutIds = this.timeoutIds || [];
          this.timeoutIds[index] = setTimeout(() => {
            if (article.quantity > 0) {
              if (article.discountType === '$' || article.discountType === '€' || article.discountType === 'CHF') {
                if (article.discount > article.priceWithVAT) {
                  article.discount = 0;
                }
                article.discountedPrice = (article.priceWithVAT - article.discount).toFixed(2);
              } else if (article.discountType === '%') {
                if (article.discount < 0) {
                  article.discount = 0;
                } else if (article.discount > 100) {
                  article.discount = 100;
                }
                const percentageDiscount = (article.discount / 100) * article.priceWithVAT;
                article.discountedPrice = (article.priceWithVAT - percentageDiscount).toFixed(2);
              }

              if (article.discount === '') {
                article.discount = 0;
              }

              article.valueWithVAT = (article.discountedPrice * article.quantity).toFixed(2);
            }
          }, 100);
        });
      },
      deep: true,
    },
    searchArticle(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.productInStock_articleNumberLike(value);
        }
      }, 500);
      this.warningText = ''
    },
    searchCode(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.filteredDressesByName({
            code: value,
            name: null,
          });
        }
      }, 500);
      this.warningText = ''
    },
    searchBarcode(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.loadArticleByBarcode({
            barcodeLike: value,
          });
        }
      }, 500);
      this.warningText = ''
    },
  },
  methods: {
    ...mapActions(['totalPriceByMaterialId',
      'resetMaterialVariant',
      'variantsByDropdownName',
      'addMaterial',
      'loadMaterials',
      'editMaterialReq',
      'loadMaterialVariant',
      'addMaterialVariant',
      'changeLoadingtoTrue',
      'resetVariantsName',
      'resetProductInStock',
      'variantsByDropdownCode',
      'resetFilteredDresses',
      'resetLoadBarcode',
      'loadArticleByBarcode',
      'receiveVariantsAndPurchase',
      'loadSuppliers',
      'productInStock_articleNumberLike',
      'filteredDressesByName',
      'loadColors',
      'loadPriceByBarcode',
      'locationPagination',
      'loadPriceForArticle',
    ]),
    getArticlePrice(inventoryProductId) {
      this.loadPriceForArticle({
        inventoryProductId,
      })
    },
    toggleCollapse(index) {
      this.articleList[index].isCollapsed = !this.articleList[index].isCollapsed;
    },
    validateMaterials() {
      this.articleList.forEach((material) => {
        if (material.valueWithVAT <= 0 || material.valueWithVAT === null || material.valueWithVAT === '') {
          material.validationValueWithVAT = true;
        } else {
          material.validationValueWithVAT = false;
        }

        if (material.quantity <= 0 || material.quantity === null || material.quantity === '') {
          material.validationamount = true;
        } else {
          material.validationamount = false;
        }

        if (material.priceWithoutVAT <= 0 || material.priceWithoutVAT === null || material.priceWithoutVAT === '') {
          material.validationPriceWithoutVAT = true;
        } else {
          material.validationPriceWithoutVAT = false;
        }

        if (material.priceWithVAT <= 0 || material.priceWithVAT === null || material.priceWithVAT === '') {
          material.validationPriceWithVAT = true;
        } else {
          material.validationPriceWithVAT = false;
        }
      });
    },
    calculateFromAmount(material) {
      if (material.quantity > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.quantity).toFixed(2));
        material.valueWithoutVAT = parseFloat((material.priceWithoutVAT * material.quantity).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromPriceWithVAT(material) {
      if (material.quantity > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.quantity).toFixed(2));
        material.valueWithoutVAT = parseFloat((material.priceWithoutVAT * material.quantity).toFixed(2));
      } else {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromSelectedVAT(material) {
      if (material.quantity > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.quantity).toFixed(2));
        material.valueWithoutVAT = parseFloat((material.priceWithoutVAT * material.quantity).toFixed(2));
      } else if (material.priceWithoutVAT > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }

      if (material.priceWithVAT > 0) {
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromPriceWithoutVAT(material) {
      if (material.quantity > 0) {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
        material.valueWithVAT = parseFloat((material.priceWithVAT * material.quantity).toFixed(2));
        material.valueWithoutVAT = parseFloat((material.priceWithoutVAT * material.quantity).toFixed(2));
      } else {
        material.priceWithVAT = parseFloat((material.priceWithoutVAT * (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    calculateFromValueWithVAT(material) {
      if (material.quantity > 0) {
        material.priceWithVAT = parseFloat((material.valueWithVAT / material.quantity).toFixed(2));
        material.priceWithoutVAT = parseFloat((material.priceWithVAT / (1 + material.selectedVAT / 100)).toFixed(2));
      }
      this.validateMaterials()
    },
    removeArticle(index) {
      this.articleList.splice(index, 1);
    },
    toggleName(value) {
      this.toggleSearch = value;
      this.searchArticle = '';
      this.searchCode = '';
      this.searchBarcode = '';
      this.removeLists();
    },
    async searchByOn(value) {
      this.loadPriceForArticle({
        inventoryProductId: value.inventoryId,
      }).then((response) => {
        const duplicate = this.articleList.find((material) => {
          return material.orderedProductId === value.inventoryId;
        });
        if (duplicate) {
          this.warningText = 'There is already the same article.';
          this.resetProductInStock();
          this.skipWatch = true;
          this.searchArticle = '';
          return;
        }
        this.articleList.push({
          orderedArticleNumber: null,
          articleNumber: value.articleNumber,
          inventoryProductId: null,
          locationId: null,
          locationName: null,
          productInStockId: null,
          sellPrice: 0,
          discount: 0,
          currency: '€',
          sellPriceWithDiscount: 0,
          fileUrl: null,
          typeOfArticle: 'Catalog article',
          pickedUpDate: moment().format('YYYY-MM-DD'),
          orderedProductId: value.inventoryId,
          valueWithVAT: (response || 0),
          valueWithoutVAT: parseFloat((((response || 0) / (1 + 18 / 100)) * 1).toFixed(2)),
          quantity: 1,
          priceWithVAT: (response || 0),
          priceWithoutVAT: parseFloat(((response || 0) / (1 + 18 / 100)).toFixed(2)),
          selectedVAT: 18,
          validationPriceWithoutVAT: (response || 0) < 0,
          validationamount: (response || 0) < 0,
          validationPriceWithVAT: (response || 0) < 0,
          validationValueWithVAT: (response || 0) < 0,
          discountedPrice: (response || 0),
          discountType: '€',
          isCollapsed: true,
        });
        this.skipWatch = true;
        this.searchArticle = '';
        this.resetProductInStock();
      });
    },
    async searchByCode(value) {
      const duplicate = this.articleList.find((material) => { return material.orderedProductId === value.mainDressCategoryId });

      if (duplicate) {
        this.warningText = 'There is already the same article.';
        this.resetFilteredDresses();
        this.skipWatch = true;
        this.searchCode = '';
        return;
      }
      this.articleList.push({
        orderedArticleNumber: null,
        articleNumber: `${value.name} - ${value.code}`,
        inventoryProductId: null,
        locationId: null,
        locationName: null,
        productInStockId: null,
        sellPrice: 0,
        discount: 0,
        currency: '€',
        sellPriceWithDiscount: 0,
        fileUrl: null,
        typeOfArticle: 'Catalog article',
        pickedUpDate: moment().format('YYYY-MM-DD'),
        orderedProductId: value.mainDressCategoryId,
        valueWithVAT: 0,
        valueWithoutVAT: 0,
        quantity: 0,
        priceWithVAT: 0,
        priceWithoutVAT: 0,
        selectedVAT: 18,
        validationPriceWithoutVAT: true,
        validationamount: true,
        validationPriceWithVAT: true,
        validationValueWithVAT: true,
        discountedPrice: 0,
        discountType: '€',
        isCollapsed: true,
      });
      this.skipWatch = true;
      this.searchCode = '';
      this.resetFilteredDresses();
    },
    async searchByBarcode(value) {
      this.loadPriceByBarcode({
        barcodeLike: value.barcode,
      }).then((response) => {
        const duplicate = this.articleList.find((material) => { return material.orderedProductId === value.inventoryId });
        if (duplicate) {
          this.warningText = 'There is already the same article.';
          this.resetLoadBarcode();
          this.skipWatch = true;
          this.searchBarcode = '';
          return;
        }
        this.articleList.push({
          orderedArticleNumber: null,
          articleNumber: `${value.articleNumber} - ${value.barcode}`,
          inventoryProductId: null,
          locationId: null,
          locationName: null,
          productInStockId: null,
          sellPrice: 0,
          discount: 0,
          currency: '€',
          sellPriceWithDiscount: 0,
          fileUrl: null,
          typeOfArticle: 'Catalog article',
          pickedUpDate: moment().format('YYYY-MM-DD'),
          orderedProductId: value.inventoryId,
          valueWithVAT: response,
          valueWithoutVAT: parseFloat(((response / (1 + 18 / 100)) * 1).toFixed(2)),
          quantity: 1,
          priceWithVAT: response,
          priceWithoutVAT: parseFloat((response / (1 + 18 / 100)).toFixed(2)),
          selectedVAT: 18,
          validationPriceWithoutVAT: false,
          validationamount: false,
          validationPriceWithVAT: false,
          validationValueWithVAT: false,
          discountedPrice: response,
          discountType: '€',
          isCollapsed: true,
        });
        this.skipWatch = true;
        this.searchBarcode = '';
        this.resetLoadBarcode();
      });
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getArticleNameLike.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getArticleNameLike[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDown1(ev) {
      ev.preventDefault()
      if (this.arrowCounter1 < this.getFilteredDresses.length - 1) {
        this.arrowCounter1 += 1;
        this.fixScrolling();
      }
    },
    onArrowUp1(ev) {
      ev.preventDefault()
      if (this.arrowCounter1 > 0) {
        this.arrowCounter1 -= 1;
        this.fixScrolling()
      }
    },
    inputChanged1(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByCode(this.getFilteredDresses[this.arrowCounter1])
        this.removeLists()
        this.arrowCounter1 = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDownBarcode(ev) {
      ev.preventDefault()
      if (this.arrowCounter1 < this.getArticleByBarcode.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUpBarcode(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChangedBarcode(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByBarcode(this.getArticleByBarcode[this.arrowCounter1])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    removeLists() {
      this.resetProductInStock()
      this.resetFilteredDresses();
      this.resetLoadBarcode()
    },
    addCustomArticle() {
      this.articleList.push({
        orderedArticleNumber: null,
        articleNumber: null,
        inventoryProductId: null,
        locationId: null,
        locationName: null,
        productInStockId: null,
        sellPrice: 0,
        discount: 0,
        currency: '€',
        sellPriceWithDiscount: 0,
        fileUrl: null,
        typeOfArticle: 'Custom article',
        pickedUpDate: moment().format('YYYY-MM-DD'),
        orderedProductId: null,
        valueWithVAT: 0,
        valueWithoutVAT: 0,
        quantity: 0,
        priceWithVAT: 0,
        priceWithoutVAT: 0,
        selectedVAT: 18,
        validationPriceWithoutVAT: true,
        validationamount: true,
        validationPriceWithVAT: true,
        validationValueWithVAT: true,
        discountedPrice: 0,
        discountType: '€',
        isCollapsed: true,
      });
    },
    resetQuantityIfNegative(article) {
      if (article.quantity < 0 || article.quantity == '') {
        article.quantity = 0;
        article.valueWithVAT = 0;
        article.validationValueWithVAT = true;
      }
    },
  },
};
</script>

  <style>
.button3 {
  background: #FF274F !important;
  font-weight: 500;
  font-size: 15px !important;
  color: white;
  border-radius: 6px;
  height: 38px;
  width: 250px;
  border: none;
  padding: 6px;
}

.button3:hover {
  border: 1px solid #FF274F !important;
  background: white !important;
  color: #FF274F !important;
  transition: ease-in-out 0.3s;
  height: 38px;
  width: 250px;
}
  .active2{
  color: #212529;
  font-weight: bold;
}
  .dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
  z-index:3;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}
  .custom-modal__wrapper {
    display: flex;
    justify-content: space-between;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
  }
  .paragraphText {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  .paragraphText h3 {
    color: red;
    width: auto;
    background-color: red;
    color: white;
    font-size: 20px;
    padding: 10px;
    border-radius: 12px;
    margin: 0px;
  }
  .formInput {
    display: flex;
    gap: 10px;
    padding-top: 10px;;
  }
  .currencyStyle {
    display: block;
    padding: 0.469rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  </style>
