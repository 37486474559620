<template>
  <b-modal
    id="add-quantity"
    ref="modal"
    header-class="headerModal"
    centered
    size="s"
    :title="`${$t('AddQuantity')}:${articleName?.articleNumber} - ${locationValues?.locationName}`"
    hide-footer
  >
    <b-form-group
      :label="$t('Quantity')"
    >
      <b-form-input
        v-model.number="quantity"
        type="number"
      />
    </b-form-group>
    <p
      v-if="quantity == 0 && buttonSubmited == true"
      style="color:red;font-weight: 600;"
    >
      {{ $t('Quantity') }} > 0
    </p>
    <div class="buttonsEverywhere">
      <b-button
        type="button"
        variant="primary"
        class="buttonSubmit"
        style="margin-top: 10px;"

        @click="onSubmit"
      >
        {{ $t('Submit') }}
      </b-button>
      <b-button
        type="button"
        variant="danger"
        class="buttonCancel"
        style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white;font-weight: 400;"

        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>


export default {
  components: {
  },
  props: ['locationValues', 'articleName'],
  data() {
    return {
      quantity: 0,
      buttonSubmited: false,
    }
  },
  watch: {
    quantity(value) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        if (value > this.locationValues.quantity || value < 0) {
          this.quantity = 0;
        }
      }, 50);
    },
  },
  methods: {
    onSubmit() {
      this.buttonSubmited = true
      if (this.quantity == 0 && this.buttonSubmited == true) {
        return
      }
      const object = {
        quantity: this.quantity,
        productInStockId: this.locationValues.productInStockId,
      }
      this.$emit('addQuantityToOrder', object)
      this.$refs.modal.hide()
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.buttonSubmited = false
      this.quantity = 0
    },
  },
}
</script>

<style>

</style>
