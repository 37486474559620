<template>
  <b-modal
    id="create-order-articles"
    ref="modal"
    header-class="headerModal"
    centered
    size="xl"
    title="Create order modal"
    hide-footer
    @close="onCancel"
  >
    <p
      v-if="clientError && buttonSubmited"
      style="color:red;font-weight: 600;"
    >
      {{ $t('NoClientFound') }}
    </p>
    <ClientSearch
      @sentClientData="sentClientDatas"
    />
    <p
      v-if="articleList.length == 0 && buttonSubmited"
      style="color:red;font-weight: 600;margin-top: 10px;margin-bottom: 5px"
    >
      {{ $t('NoArticlesFound') }}
    </p>
    <ArticleSell
      :article-list="articleList"
    />
    <div style="margin-left:5px;margin-top: 10px;width:100%;border:3px solid lightgray;border-radius: 6px;padding:15px">
      <div style="display:flex;gap:10px">
        <b-form-group
          :label="$t('sellDate')"
          style="margin-top:5px;margin-bottom:5px;"
        >
          <date-picker
            v-model="sellDate"
            format="YYYY-MM-DD"
            value-type="format"
            :placeholder="$t('SelectD')"
          />
        </b-form-group>
      </div>
      <div style="padding:15px 0px 15px 0px;">
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>{{ $t('PriceNoVat') }}</th>
              <th>{{ $t('Vat') }}</th>
              <th>{{ $t('PriceWithVat') }}</th>
              <th>{{ $t('Discount') }}</th>
              <th>{{ $t('DiscountedPrice') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div style="display:flex;">
                  <b-form-input
                    v-model.number="priceWithoutVAT"
                    type="number"
                    :placeholder="$t('Value')"
                    @input="calculateFromPriceWithoutVAT(priceWithoutVAT)"
                  />
                  <b-form-select
                    v-model="currency"
                    dropup
                    required
                    :options="currencies"
                    aria-placeholder="Select order type"
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                          padding: 0.469rem 0.75rem;
                          font-size: 1rem;
                          font-weight: 400;
                          line-height: 1.5;
                          color: #212529;
                          background-color: #fff;
                          background-clip: padding-box;
                          border: 1px solid #ced4da;
                          border-radius: 0.25rem;
                          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                </div>
              </td>
              <td>
                <div style="padding:5px;">
                  <b-form-radio-group
                    v-model="selectedVAT"
                    :options="vatOptions"
                    style="display:flex;gap: 10px;align-items: center;"
                    @input="calculateFromSelectedVAT(selectedVAT)"
                  />
                </div>
              </td>
              <td>
                <div style="display:flex;margin-right: 5px;">
                  <b-form-input
                    v-model.number="totalCost"
                    type="number"
                    :placeholder="$t('Value')"
                    @input="calculateFromPriceWithVAT(totalCost)"
                  />
                  <b-form-select
                    v-model="currency"
                    dropup
                    required
                    :options="currencies"
                    aria-placeholder="Select order type"
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                          padding: 0.469rem 0.75rem;
                          font-size: 1rem;
                          font-weight: 400;
                          line-height: 1.5;
                          color: #212529;
                          background-color: #fff;
                          background-clip: padding-box;
                          border: 1px solid #ced4da;
                          border-radius: 0.25rem;
                          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                </div>

              </td>
              <td>
                <div style="display:flex;margin-right: 5px;">
                  <b-input
                    v-model.number="discount"
                    type="number"
                    default="0"
                    :disabled="totalCost == 0 || totalCost == ''"
                  />
                  <b-form-select
                    v-model="discountType"
                    style="height:38px; border-radius: 0.25rem; border: 1px solid #EAECF0"
                  >
                    <option value="$">
                      $
                    </option>
                    <option value="€">
                      €
                    </option>
                    <option value="CHF">
                      CHF
                    </option>
                    <option value="%">
                      %
                    </option>
                  </b-form-select>
                </div>
              </td>
              <td>
                <div style="display:flex">

                  <b-input
                    v-model.number="discountedPrice"
                    disabled
                    type="number"
                    style="width: 90%;"
                  />
                  <b-form-select
                    v-model="currency"
                    dropup
                    required
                    :options="currencies"
                    aria-placeholder="Select order type"
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                          padding: 0.469rem 0.75rem;
                          font-size: 1rem;
                          font-weight: 400;
                          line-height: 1.5;
                          color: #212529;
                          background-color: #fff;
                          background-clip: padding-box;
                          border: 1px solid #ced4da;
                          border-radius: 0.25rem;
                          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          class="team_table"
        >
          <thead>
            <tr>
              <th>{{ $t('PaidPrice') }}</th>
              <th>{{ $t('Account') }}</th>
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="width:30% !important;">
                <div style="display:flex;margin-top:10px;">
                  <b-input
                    v-model.number="paidAmount"
                    type="number"
                    style="width:220px;"
                  />
                  <b-form-select
                    v-model="currency "
                    dropup
                    required
                    :options="currencies"
                    aria-placeholder="Select order type"
                    aria-describedby="input-1-live-feedback"
                    style="display: block;
                          padding: 0.469rem 0.75rem;
                          font-size: 1rem;
                          font-weight: 400;
                          line-height: 1.5;
                          color: #212529;
                          background-color: #fff;
                          background-clip: padding-box;
                          border: 1px solid #ced4da;
                          border-radius: 0.25rem;
                          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
                  />
                </div>
              </td>
              <td>
                <div
                  style="width: 70%;"
                >
                  <vue-select
                    v-model="accountId"
                    :options="getAccountDetails"
                    :reduce="(e) => e.accountId"
                    label="name"
                    :placeholder="$t('Select')"
                    style="width: 70%;"
                  />
                </div>
              </td>
              <td />
              <td />
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div style="margin-left:5px;margin-top: 10px;width:100%;border:3px solid lightgray;border-radius: 6px;padding:15px">
      <b-form-group
        :label="$t('ShippingCost')"
        style="width:30%"
      >
        <div
          style="display:flex;flex-direction:column;gap:5px;"
        >
          <div style="display:flex;flex-direction:row;gap:5px;">
            <b-form-input
              v-model.number="shippings.cost"
              :placeholder="$t('Cost')"
              type="number"
            />
            <b-form-select
              v-model="shippings.currency"
              dropup
              :options="[{ value: null, text: 'None' }, ...currencies]"
              aria-placeholder="Select order type"
              aria-describedby="input-1-live-feedback"
              style="display: block;
                        padding: 0.469rem 0.75rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #212529;
                        background-color: #fff;
                        background-clip: padding-box;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;"
              :disabled="shippings.cost == null || shippings.cost == ''"
            />
          </div>
          <b-form-group
            :label="$t('paidAmount')"
            style="width: 100%"
          />
          <b-form-input
            v-model.number="shippings.paidAmount"
            :placeholder="$t('paidAmount')"
            type="number"
            :disabled="shippings.cost == null || shippings.cost == ''"
          />
          <b-form-group
            :label="$t('Notes')"
            style="width:100%"
          >
            <b-form-textarea
              v-model="shippings.notes"
              style="width:100%"
              :placeholder="$t('Select')"
              type="number"
            />
          </b-form-group>
        </div>
      </b-form-group>
      <b-form-group
        :label="$t('SelectAccount')"
        style="margin-top:5px;margin-bottom:5px;width:30%"
      >
        <vue-select
          v-model="shippings.accountId"
          :options="getAccountDetails"
          required
          label="name"
          :reduce="(account) => account.accountId"
          :placeholder="$t('SelectAccount')"
          aria-describedby="input-1-live-feedback"
          :disabled="(shippings.cost == null || shippings.cost == '') || (shippings.paidAmount == null || shippings.paidAmount == '')"
        />
        <p
          v-if="shippings.cost != null && shippings.paidAmount != null && shippings.accountId == null"
          style="color:red"
        >{{ $t('FillData') }}</p>
      </b-form-group>
    </div>
    <div style="display: flex;flex-direction:column;margin: 10px 0px 10px 5px;border:3px solid lightgray;border-radius: 6px;padding:15px">
      <div style="display:flex;gap:10px;">
        <StoresDropdown
              :filiale="filiale"
              @updateFiliale="updateFiliale"
            />
        <b-form-group
          v-if="getLoggedInUser.role !== 'SalesPerson' && getLoggedInUser.role !== 'OnlineSales' "
          :label="$t('SelectSalesPerson')"
          style="width:100%"
        >
          <vue-select
            v-model="roleId"
            :options="getUsersMultipleRoles"
            required
            label="fullName"
            :reduce="(account) => account.userID"
            :placeholder="$t('SelectSalesPerson')"
            aria-describedby="input-1-live-feedback"
            style="width: 100%;"
          />
        </b-form-group>
      </div>
      <b-form-group
        :label="$t('InoviceRemark')"
        style="width:100%;padding-top:5px;"
      >
        <b-form-textarea
          v-model="invoiceRemark"
          :placeholder="$t('Notes')"
          style="width:100%"
        />
      </b-form-group>
    </div>
    <b-form-group
      :label="$t('Notes')"
    >
      <b-form-textarea
        v-model="description"
        :placeholder="$t('RequestedChangesByCustomer')"
      />
    </b-form-group>
    <div
      class="buttonsEverywhere"
      style="margin-top: 0%"
    >
      <button
        type="button"
        variant="none"
        style="margin-top: 20px;"
        class="buttonSubmit"
        @click="onSubmit"
      >
        {{ $t('Submit') }}
      </button>
      <b-button
        type="reset"
        variant="danger"
        class="buttonCancel"
        style="margin-right: 15px; margin-top: 20px; background: white; color: black; border-color: white;font-weight: 400;"
        @click="onCancel"
      >
        {{ $t('Cancel') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from 'vue2-datepicker';
import moment from 'moment';
import ClientSearch from './modals/ClientSearch.vue';
import ArticleSell from './modals/ArticleSell.vue';
import 'vue2-datepicker/index.css';
import StoresDropdown from '../StoresDropdown.vue';



export default {
  components: {
    ClientSearch,
    ArticleSell,
    DatePicker,
    StoresDropdown,
  },
  props: ['articleList'],
  data() {
    return {
      totalCost: 0,
      amount: 1,
      priceWithoutVAT: 0,
      selectedVAT: 18,
      vatOptions: [
        { text: '18%', value: 18 },
        { text: '8%', value: 8 },
        { text: '0%', value: 0 },
      ],
      discountType: '€',
      currency: '€',
      discount: 0,
      includeTax: false,
      taxRate: 0,
      buttonSubmited: false,
      paidAmount: 0,
      sellDate: moment().format('YYYY-MM-DD'),
      invoiceRemark: null,
      description: null,
      filiale: { storeId: null, storeName: 'No filiale', storeGLN: null },
      oldFiliale: { storeId: null, storeName: 'No filiale', storeGLN: null },
      roleId: null,
      accountId: null,
      clientData: null,
      clientError: false,
      shippings: {
        cost: null,
        accountId: null,
        currency: null,
        notes: '',
        paidAmount: '',
      },
      currencies: ['€', '$', 'CHF'],
    }
  },
  computed: {
    ...mapGetters(['getAccountDetails', 'getLoggedInUser', 'getAllStoresDropdown', 'getUsersMultipleRoles']),
    discountedPrice: {
      get() {
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          return this.totalCost - this.discount;
        } if (this.discountType === '%') {
          if (this.totalCost === 0) {
            return 0; // Avoid division by zero
          }
          const percentageDiscount = (this.discount / 100) * this.totalCost;
          return (this.totalCost - percentageDiscount).toFixed(2);
        }

        // Default to 0 when no discount type is selected
        return 0;
      },
      set(value) {
        // You can add validation here if needed
        if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
          this.discount = this.totalCost - value;
        } else if (this.discountType === '%') {
          if (this.totalCost === 0) {
            this.discount = 0; // Avoid division by zero
          } else {
            const percentageDiscount = (this.totalCost - value) / (this.totalCost * 100);
            this.discount = percentageDiscount;
          }
        }
      },
    },
  },
  watch: {
    articleList: {
      handler(newValue) {
        this.totalCost = newValue.reduce((total, article) => {
          return total + parseFloat(article.valueWithVAT);
        }, 0);
        this.totalCost = parseFloat(this.totalCost.toFixed(2));
        this.calculateFromPriceWithVAT(this.totalCost);
      },
      deep: true,
    },
    'shippings.cost': function (newCost) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (newCost < 0 || newCost == '') {
          this.shippings.cost = null
          this.shippings.paidAmount = 0;
        }
      }, 0);
    },
    'shippings.paidAmount': function (newCost) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (newCost > this.shippings.cost) {
          this.shippings.paidAmount = 0;
        }
        if (newCost < 0 || newCost == '') {
          this.shippings.paidAmount = null
          this.shippings.accountId = null
        }
      }, 0);
    },
    includeTax(newIncludeTax) {
      if (newIncludeTax) {
        this.taxRate = 18;
      } else {
        this.taxRate = 0;
      }
    },
    priceWithoutVAT(newPrice) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (newPrice == '' || newPrice < 0 || newPrice == null) {
          this.totalCost = 0
          this.priceWithoutVAT = 0
        }
      }, 0);
    },
    totalCost(newPrice) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (newPrice == '' || newPrice < 0 || newPrice == null) {
          this.totalCost = 0
          this.priceWithoutVAT = 0
        }
      }, 0);
      if (newPrice < this.discount) {
        this.discount = 0;
      }
    },
    discount(newDiscount) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      if (this.discountType === '$' || this.discountType === '€' || this.discountType === 'CHF') {
        if (newDiscount > this.totalCost && newDiscount > this.discountedPrice) {
          this.discount = 0;
        }
      } else if (this.discountType === '%') {
        if (this.getLoggedInUser.role === 'SalesPerson' && newDiscount > 10) {
          this.discount = 0;
        } else if (this.getLoggedInUser.role === 'Manager' && newDiscount > 20) {
          this.discount = 0;
        } else if (this.getLoggedInUser.role !== 'Manager' && this.getLoggedInUser.role !== 'SalesPerson' && this.getLoggedInUser.role !== 'CEO' && this.getLoggedInUser.role !== 'Admin' && newDiscount > 5) {
          this.discount = 0;
        } else if (newDiscount > 100) {
          this.discount = 0;
        }
      }
      this.timeoutId = setTimeout(() => {
        if (newDiscount === null || newDiscount === '' || newDiscount < 0) {
          this.discount = 0;
        }
      }, 0);
    },
  },
  mounted() {
    this.usersByMultipleRoles({
      status: 'true',
      object: ['SalesPerson', 'OnlineSales'],
    })
    this.accountDetails(
      {
        isActive: true,
        bankId: null,
      },
    )
  },
  methods: {
    ...mapActions(['accountDetails', 'usersByMultipleRoles', 'loadAllStoresByDropdown']),
    updateFiliale(newFiliale) {
      this.filiale = newFiliale;
    },
    calculateFromPriceWithVAT(value) {
      if (this.amount > 0) {
        this.priceWithoutVAT = parseFloat((value / (1 + this.selectedVAT / 100)).toFixed(2));
        this.valueWithVAT = parseFloat((value * this.amount).toFixed(2));
      } else {
        this.priceWithoutVAT = parseFloat((value / (1 + this.selectedVAT / 100)).toFixed(2));
      }
    },
    calculateFromSelectedVAT(value) {
      if (this.amount > 0) {
        this.totalCost = parseFloat((this.priceWithoutVAT * (1 + value / 100)).toFixed(2));
        this.valueWithVAT = parseFloat((this.totalCost * this.amount).toFixed(2));
      } else if (this.priceWithoutVAT > 0) {
        this.totalCost = parseFloat((this.priceWithoutVAT * (1 + value / 100)).toFixed(2));
      }

      if (this.totalCost > 0) {
        this.priceWithoutVAT = parseFloat((this.totalCost / (1 + value / 100)).toFixed(2));
      }
    },
    calculateFromPriceWithoutVAT(value) {
      if (this.amount > 0) {
        this.totalCost = parseFloat((value * (1 + this.selectedVAT / 100)).toFixed(2));
        this.valueWithVAT = parseFloat((this.totalCost * this.amount).toFixed(2));
      } else {
        this.totalCost = parseFloat((value * (1 + this.selectedVAT / 100)).toFixed(2));
      }
    },
    sentClientDatas(value) {
      this.clientData = value.clientData
      this.clientError = value.errorClient
    },
    async onSubmit() {
      this.buttonSubmited = true
      if (this.clientError && this.buttonSubmited) {
        return;
      }
      if (this.shippings.cost != null) {
        if (this.shippings.cost != null && this.shippings.paidAmount != null && this.shippings.accountId == null) {
          return
        }
      }
      if (this.articleList.length == 0 && this.buttonSubmited) {
        return
      }
      const toady = moment().format('YYYY-MM-DD')
      const articles = this.articleList.map((item) => {
        return {
          quantity: item.quantity,
          price: item.priceWithVAT,
          priceWithDiscount: item.discountedPrice,
          discount: item.discount,
          productInStockId: item.productInStockId,
          productId: item.inventoryProductId || null,
          scheduledPickupDate: item.pickedUpDate !== toady ? item.pickedUpDate : null,
          pickedUpDate: item.pickedUpDate == toady ? item.pickedUpDate : null,
          orderedArticleNumber: item.orderedArticleNumber || null,
          orderedProductId: item.orderedProductId || null,
        }
      })
      const shippings = {
        cost: this.shippings.cost,
        currency: this.shippings.currency,
        accountId: this.shippings.accountId,
        notes: this.shippings.notes,
        paidAmount: this.shippings.paidAmount == '' ? 0 : this.shippings.paidAmount,
      }
      const object = {
        clientId: this.clientData.clientId,
        billingAddressDto: this.clientData.billingAddressDto,
        ordererDto: this.clientData.ordererDto,
        shippingAddressDto: this.clientData.shippingAddressDto,
        pickUpInStore: this.clientData.pickUpInStore,
        storeId: this.filiale.storeId,
        salesUserId: this.roleId,
        description: this.description,
        orderType: this.clientData.orderType,
        accountId: this.accountId,
        currency: '€',
        price: this.totalCost,
        discount: this.discount,
        paidAmount: this.paidAmount,
        paidNotes: null,
        taxRate: this.selectedVAT,
        invoiceRemark: this.invoiceRemark,
        shippingCostRequestDto: shippings,
        orderItems: articles,
        pickUpLocationId: null,
        sellDate: this.sellDate,
      }
      this.$emit('createOrder', object)
      this.$refs.modal.hide()
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.$emit('resetArticlesFromModal')
      this.buttonSubmited = false
      this.taxRate = 0
      this.includeTax = false
      this.sellDate = moment().format('YYYY-MM-DD')
      this.accountId = null
      this.roleId = null
      this.invoiceRemark = null
      this.shippings.cost = null
      this.shippings.currency = '€'
      this.shippings.accountId = null
      this.shippings.notes = ''
      this.shippings.paidAmount = ''
      this.clientData.ordererDto.salutation = null;
      this.clientData.ordererDto.firstName = '';
      this.clientData.ordererDto.lastName = '';
      this.clientData.ordererDto.email = '';
      this.clientData.ordererDto.gender = null;
      this.clientData.ordererDto.telephoneNumber = '';
      this.clientData.billingAddressDto.street = '';
      this.clientData.billingAddressDto.houseNumber = '';
      this.clientData.billingAddressDto.doorNumber = '';
      this.clientData.billingAddressDto.postalCode = '';
      this.clientData.billingAddressDto.city = '';
      this.clientData.billingAddressDto.country = '';
      this.clientData.shippingAddressDto.street = '';
      this.clientData.shippingAddressDto.houseNumber = '';
      this.clientData.shippingAddressDto.doorNumber = '';
      this.clientData.shippingAddressDto.postalCode = '';
      this.clientData.shippingAddressDto.city = '';
      this.clientData.shippingAddressDto.country = '';
      this.clientData.pickUpInStore = false;
      this.clientData.orderType = 'InStore';
      this.clientData.clientId = '00000000-0000-0000-0000-000000000000'
    },
  },
}
</script>

  <style>

  </style>
